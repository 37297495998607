import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const AnimalMerge = () => {
  useEffect(() => {
    // Check if the protocol is 'file' and show an alert
    if (window.location.protocol.startsWith('file')) {
      alert(
        "Web exports won't work until you upload them. (When running on the file: protocol, browsers block many features from working for security reasons.)"
      );
    }

    // Dynamically load the external scripts
    const loadScript = (src, type = 'text/javascript') => {
      const script = document.createElement('script');
      script.src = src;
      script.type = type;
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the external scripts
    loadScript('/scripts/modernjscheck.js');
    loadScript('/scripts/supportcheck.js');
    loadScript('/scripts/offlineclient.js', 'module');
    loadScript('/scripts/main.js', 'module');
    loadScript('/scripts/register-sw.js', 'module');

    // Function to stop the game
    const stopGame = () => {
      console.log('Stopping the game...');
      const gameCanvas = document.getElementById('gameCanvas');
      if (gameCanvas) {
        cancelAnimationFrame(window.gameLoop); // Stop game loop
        clearInterval(window.gameInterval); // Stop intervals
        gameCanvas.getContext('2d').clearRect(0, 0, gameCanvas.width, gameCanvas.height); // Clear the canvas
      }
    };

    // Handle dynamic resizing of canvas based on window size
    const handleResize = () => {
      const canvas = document.getElementById('gameCanvas');
      const container = document.getElementById('canvasContainer');
      if (canvas && container) {
        // Keep the canvas's aspect ratio while fitting the available space
        const width = container.offsetWidth;
        const height = width * (9 / 16); // 16:9 aspect ratio
        canvas.width = width;
        canvas.height = height;
      }
    };

    // Initial resize and setting event listeners
    handleResize();
    window.addEventListener('resize', handleResize);

    // Add event listeners for stopping the game
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        stopGame();
      }
    });

    window.addEventListener('beforeunload', (event) => {
      stopGame();
      event.preventDefault();
      event.returnValue = '';
    });

    // Cleanup on unmount
    return () => {
      document.removeEventListener('visibilitychange', stopGame);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('beforeunload', stopGame);
      stopGame(); // Ensure the game stops on unmount
    };
  }, []);

  const handleRefresh = (path) => {
    const gameCanvas = document.getElementById('gameCanvas');
    if (gameCanvas) {
      gameCanvas.getContext('2d').clearRect(0, 0, gameCanvas.width, gameCanvas.height); // Clear canvas on navigation
    }
    window.location.href = path; // Force full-page reload to navigate
  };

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Animals merge</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
        <meta name="generator" content="Scirra Construct" />

        <link rel="manifest" href="appmanifest.json" />
        <link rel="apple-touch-icon" sizes="128x128" href="icons/icon-128.png" />
        <link rel="apple-touch-icon" sizes="256x256" href="icons/icon-256.png" />
        <link rel="apple-touch-icon" sizes="512x512" href="icons/icon-512.png" />
        <link rel="icon" type="image/png" href="icons/icon-512.png" />
        <link rel="stylesheet" href="style.css" />
      </Helmet>

      <noscript>
        <div id="notSupportedWrap">
          <h2 id="notSupportedTitle">This content requires JavaScript</h2>
          <p className="notSupportedMessage">JavaScript appears to be disabled. Please enable it to view this content.</p>
        </div>
      </noscript>

      {/* Navigation buttons */}
      <nav style={{ marginTop: '120px', zIndex: 9999 }}>
        <button 
          className="BackButton" 
          onClick={() => handleRefresh('/')} 
          style={{
            padding: '10px 20px', 
            fontSize: '16px', 
            backgroundColor: '#aec700', 
            color: '#FFFFFF', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            display: 'flex', 
            alignItems: 'center'
          }}
        >
          <span className="arrow" style={{ marginRight: '10px' }}>←</span>
          Back
        </button>
      </nav>

      {/* Game container that holds the canvas */}
      <div 
        id="gameContainer" 
        style={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh', 
          width: '100vw', 
          backgroundColor: '#f0f0f0',
          padding: '10px',
          boxSizing: 'border-box'
        }}
      >
        <div 
          id="canvasContainer"
          style={{
            width: '100%',
            maxWidth: '944px', 
            aspectRatio: '16 / 9', // Maintain aspect ratio
            backgroundColor: '#000',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            borderRadius: '10px',
            overflow: 'hidden'
          }}
        >
          <canvas 
            id="gameCanvas" 
            style={{
              width: '100%', 
              height: '100%',
              display: 'block'
            }}
          ></canvas>
        </div>
      </div>
    </>
  );
};

export default AnimalMerge;
